<template>
  <div class="box">
    <h2>{{ $t("common.teamsStandings") }}</h2>
    <XTable
      :x-content="rows"
      :x-columns="columns"
      @row-click="go"
      sort-by="num"
      sort-dir="asc"
      class="clickable"
    >
      <template #header[TeamName]>
        <span class="logo-margin">{{ $t("common.team") }}</span>
      </template>
      <template #cell[TeamName]="{row,cell}">
        <span>
          <span
            class="team-icon"
            v-bind:style="{
              'background-image': `url('${$bucketUrl}/images/teams/${row._id}.png')`,
            }"
          ></span>
          {{ cell }}
        </span>
      </template>
      <template #cell[Streak]="{cell}">
        <template v-for="(streak, index) in cell.slice(0, 5).reverse()">
          <span :key="index" v-if="streak == 'W'" class="form form-w">W</span>
          <span :key="index" v-else-if="streak == 'L'" class="form form-l"
            >L</span
          >
          <span :key="index" v-else-if="streak == 'T'" class="form form-t"
            >T</span
          >
        </template>
        <span v-if="cell.length == 0">N/A</span>
      </template>
    </XTable>
  </div>
</template>

<script>
import XTable from "./XTable.vue";
export default {
  props: ["content"],
  components: {
    XTable,
  },
  data() {
    return {
      columns: [
        {
          display: "#",
          name: "num",
          align: "center",
          sortable: true,
          minimal: true,
        },
        { t: "common.team", name: "TeamName", sortable: true },
        {
          t: "stats.gp",
          name: "GamesPlayed",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
        {
          t: "stats.w",
          name: "Wins",
          align: "center",
          sortable: true,
          hideable: true,
          preferDesc: true,
        },
        {
          t: "stats.d",
          name: "Tie",
          align: "center",
          sortable: true,
          hideable: true,
          preferDesc: true,
        },
        {
          t: "stats.l",
          name: "Loss",
          align: "center",
          sortable: true,
          hideable: true,
          preferDesc: true,
        },
        {
          t: "stats.gd",
          name: "GD",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
        {
          t: "stats.pts",
          name: "Points",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
        { t: "stats.form", name: "Streak", align: "center", hideable: true },
      ],
    };
  },
  methods: {
    go(e) {
      let leagueID = this.$route.params.leagueID
        ? this.$route.params.leagueID
        : this.$route.params.id;
      let seasonID = this.$route.params.seasonID
        ? `/${this.$route.params.seasonID}`
        : '';
      this.$router.push({
          path: `/team/${e._id}/${leagueID}${seasonID}`,
        });
    },
  },
  computed: {
    rows() {
      return this.content
        .slice(0)
        .map((e, i) => {
          return {
            ...e,
            num: i + 1,
          };
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/theme.scss";
.form {
  width: 22px;
  display: inline-block;
  margin: 0 3px;
}
.form-w {
  background-color: $primary;
}
.form-l {
  background-color: $secondary;
}
.form-t {
  background-color: #888;
}
.logo-margin {
  margin-left: 2em;
}
</style>
