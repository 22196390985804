<template>
  <div class="box">
    <h2>{{ $t("common.players") }}</h2>
    <XTable
      :x-content="content"
      :x-columns="columns"
      @row-click="go"
      class="clickable"
    >
      <template #header[Goals]>
        <span class="icon">&#xe800;</span>
      </template>
      <template #header[Assists]>
        <span class="icon">&#xe801;</span>
      </template>
      <template #header[YellowCard]>
        <span class="icon yellow">&#xe802;</span>
      </template>
      <template #header[Double_Yellow_Card]>
        <span class="icon yellow">&#xe803;</span>
      </template>
      <template #header[RedCard]>
        <span class="icon red">&#xe802;</span>
      </template>
    </XTable>
  </div>
</template>

<script>
import XTable from "./XTable.vue";
export default {
  components: {
    XTable,
  },
  props: ["content"],
  data() {
    return {
      columns: [
        { t: "#", name: "JerseyNumber", sortable: false, hideable: true },
        { t: "common.firstname", name: "First_Name", sortable: true },
        { t: "common.lastname", name: "Last_Name", sortable: true },
        {
          t: "stats.gp",
          name: "GamesPlayed",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
        {
          display: "Goals",
          name: "Goals",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
        {
          display: "Assists",
          name: "Assists",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
        {
          display: "Yellow Card",
          name: "YellowCard",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
        {
          display: "Double Yellow Card",
          name: "Double_Yellow_Card",
          align: "center",
          sortable: true,
          preferDesc: true,
          hideable: true,
        },
        {
          display: "Red Card",
          name: "RedCard",
          align: "center",
          sortable: true,
          preferDesc: true,
        },
      ],
    };
  },
  methods: {
    go(e) {
      this.$router.push({ path: `/players/${e.Player_id}` });
    },
  },
};
</script>
<style lang="scss" scoped>
.yellow {
  color: yellow;
}
.red {
  color: red;
}
.icon {
  line-height: 18px;
  font-size: 18px;
  //text-shadow: 0.5px 0.5px #000;
}
</style>
