<template>
  <div class="team-banner" :style="[{'cursor': subtitle ? 'pointer' : 'auto'}]" @click="redirect">
    <div class="team-name" :style="{'background-image': `url('${$bucketUrl}/images/teams/${teamId}.png')`}">
      <h1>{{teamName}}</h1>
      <hr />
      <p v-if="subtitle">{{subtitle}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["teamId", "teamName", "subtitle"],
  methods:{
    redirect(){
      if(!this.subtitle) return;
      this.$router.push({ path: `/team/${this.teamId}`});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.team-banner {
  @extend %secondary-pattern;
  border-bottom: 10px solid $primary;
  margin: 15px 0;
}
.team-name {
  color: white;
  text-align: center;
  padding: 15px;
  background-size: 140px auto;
  background-repeat: no-repeat;
  background-position: left -20px center;
  text-transform: uppercase;
  h1 {
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }
  hr {
    width: 200px;
    border-top: 1px solid white;
    margin: 0 auto;
  }
}
</style>