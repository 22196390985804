<template>
  <div class="box">
    <h2>{{$t("common.matches")}}</h2>
    <XTable :x-content="content" :x-columns="columns" @row-click="goEvent" class="clickable">
      <template #cell[TeamH]="{row,cell}">
        <span class="team-name">
          {{cell}}
          <span
            class="team-icon"
            v-bind:style="{'background-image': `url('${$bucketUrl}/images/teams/${row.HomeTeam_id}.png')`}"
          ></span>
        </span>
      </template>
      <template #cell[TeamA]="{row,cell}">
        <span class="team-name">
          <span
            class="team-icon"
            v-bind:style="{'background-image': `url('${$bucketUrl}/images/teams/${row.AwayTeam_id}.png')`}"
          ></span>
          {{cell}}
        </span>
      </template>
      <template #cell[Score]="{row}">
        <div class="match-info">
          <template v-if="new Date(row.Date).getTime()<Date.now()">
            <p class="date">{{$DateTime.fromISO(row.Date).setLocale($i18n.locale).toFormat("MMM d")}}</p>
            <p class="score">{{row.Home_Score}}-{{row.Away_Score}}</p>
          </template>
          <template v-else>
            <p class="date">{{$DateTime.fromISO(row.Date).setLocale($i18n.locale).toFormat("MMM d h:mm a")}}</p>
            <p class="field">{{row.Field}}</p>
          </template>
        </div>
      </template>
    </XTable>
  </div>
</template>

<script>
import XTable from "./XTable.vue";
export default {
  components: {
    XTable
  },
  props: ["content"],
  data() {
    return {
      columns: [
        { t: "common.home", name: "TeamH", align: "right" },
        { t: "common.score", name: "Score", align: "center" },
        { t: "common.away", name: "TeamA", align: "left" }
      ]
    };
  },
  methods: {
    goEvent(e) {
      this.$router.push({ path: `/event/${e._id}` });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/theme.scss";

.team-name {
  font-size: 20px;
}
.match-info {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .date {
    font-size: 13px;
  }
  .score {
    @extend %font-bold;
    font-size: 20px;
  }
  .field {
    @extend %font-bold;
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {

  .team-name {
    font-size: 15px;
    margin-left: 5px;
  }
  .match-info {
    height: 30px;
    .date {
      font-size: 10px;
    }
    .score {
      @extend %font-bold;
      font-size: 15px;
    }
    .field {
      @extend %font-bold;
      font-size: 10px;
    }
  }
}
</style>