<template>
  <div>
    <ul class="tabs-list">
      <li
        v-for="tab in xTabs"
        :key="tab.name"
        @click="currentTab = tab"
        :class="{ current: currentTab == tab}"
      >{{$t(tab.display)}}</li>
    </ul>
    <div v-for="tab in xTabs" :key="tab.name" v-show="currentTab == tab">
      <slot :name="'tab['+tab.name+']'"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["xTabs"],
  data() {
    return {
      currentTab: null
    };
  },
  created() {
    if (this.xTabs && this.xTabs[0]) {
      this.currentTab = this.xTabs[0];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.tabs-list {
  display: flex;
  list-style-type: none;
  color: white;
  margin: 0 -3px;
  & > li {
    flex: 0 1 200px;
    text-align: center;
    background-color: darken($secondary, 10);
    font-size: 20px;
    padding: 5px;
    cursor:pointer;
    margin: 0 3px;
    &.current{
        background-color: $secondary;
    }
  }
  & > li:not(.current):hover {
    background-color: darken($secondary, 5);
  }
}
</style>