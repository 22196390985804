<template>
  <div class="team" v-if="!page404">
    <XNav :content="[{ t: 'common.teams' }, { name: teamData.Name }]" />
    <TeamBanner
      v-if="leagueInfo"
      :team-name="teamData.Name"
      :team-id="$route.params.id"
      :subtitle="leagueInfo.SeasonName + ' - ' + leagueInfo.FullName"
    />
    <XTabs :x-tabs="tabs" class="tabs">
      <template #tab[schedule]>
        <MatchTable :content="matches" />
      </template>
      <template #tab[players]>
        <PlayersTable :content="playersList" />
      </template>
      <template #tab[standings]>
        <LeagueTable :content="leagueTable" />
      </template>
    </XTabs>
  </div>
  <Page404 v-else />
</template>

<script>
import axios from "axios";
import LeagueTable from "@/components/tables/LeagueTable.vue";
import MatchTable from "@/components/tables/MatchTable.vue";
import PlayersTable from "@/components/tables/PlayersTable.vue";
import XTabs from "@/components/XTabs.vue";
import TeamBanner from "@/components/TeamBanner.vue";
import XNav from "@/components/XNav.vue";
import Page404 from "@/views/404.vue";
export default {
  components: {
    LeagueTable,
    MatchTable,
    PlayersTable,
    XTabs,
    TeamBanner,
    XNav,
    Page404,
  },
  data() {
    return {
      leagueTable: [],
      matches: [],
      playersList: [],
      teamData: {
        Name: null,
      },
      leagueID: null,
      leagueInfo: null,
      tabs: [
        { name: "schedule", display: "team.schedule" },
        { name: "players", display: "team.players" },
        { name: "standings", display: "team.standings" },
      ],
      seasonQuery: "",
      page404: false,
    };
  },
  mounted() {
    this.seasonQuery = this.$route.params.seasonID ? "&seasonID=" + this.$route.params.seasonID : "";

    Promise.all([
      this.getTeams(),
      this.getPlayersInTeam(),
      this.getLeagueInfo().then(() =>
        Promise.all([this.getTeamMatchInfo(), this.getLeagueTable()])
      ),
    ])
      .then(() => this.$store.dispatch("pageLoad", false))
      .catch(() => {
        this.page404 = true;
        this.$store.dispatch("pageLoad", false);
      });
  },
  methods: {
    goPlayerPage(item) {
      this.$router.push({ path: `/players/${item.Player_id}` });
    },
    getTeams() {
      return axios
        .get(
          `${this.$apiUrl}/teams/${this.$route.params.id}?${this.seasonQuery}`
        )
        .then((res) => {
          this.teamData = res.data;
        });
    },
    getPlayersInTeam() {
      return axios
        .get(
          `${this.$apiUrl}/teams/${this.$route.params.id}/players?${this.seasonQuery}&leagueID=${this.$route.params.leagueID}`
        )
        .then((res) => {
          this.playersList = res.data;
        });
    },
    getLeagueInfo() {
      return axios
        .get(`${this.$apiUrl}/leagues/${this.$route.params.leagueID}?${this.seasonQuery}`)
        .then((res) => {
          this.leagueInfo = res.data;
          this.leagueID = res.data._id;
        });
    },
    getTeamMatchInfo() {
      return axios
        .get(
          `${this.$apiUrl}/teams/${this.$route.params.id}/matches?leagueID=${this.$route.params.leagueID}${this.seasonQuery}`
        )
        .then((res) => {
          this.matches = res.data;
        });
    },
    getLeagueTable() {
      return axios
        .get(`${this.$apiUrl}/leagueTable/${this.$route.params.leagueID}?${this.seasonQuery}`)
        .then((res) => {
          this.leagueTable = res.data;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/theme.scss";
.tabs {
  margin: 15px 0;
}
.box {
  margin: 0;
}
.test {
  height: 200px;
}
</style>
